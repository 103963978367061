<template>
    <div class="dashboard">
        <div class="row margin-0">
            <div class="col-md-12 pad-15">
                <div class="parent margin-bottom-5">
                    <div class="parent-body">
                        <div class="row margin-0 margin-bottom-10">
                            <div class="col-12 pad-0 pad-top-5 text-center">
                                <h4>Reports</h4>
                            </div>
                            <div class="col-2 pad-0">
                                <div class="form-group">
                                    <label for="">Report</label>
                                    <select
                                        name=""
                                        id=""
                                        v-model="filter.report"
                                        @change="getStats()"
                                        class="form-control form-control-sm"
                                    >
                                        <option :value="null">Select Report</option>
                                        <option value="GST_REPORT">GST Report</option>
                                        <option value="COINS_REPORT">Coins Report</option>
                                        <option value="EXPORT_DOMESTIC">Export Vs Domestic Report</option>
                                        <option value="DISCOUNT_EXTENDED">Discount Extended Report</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col">
                                
                            </div>
                            <div class="col-2 pad-0">
                                <div class="form-group">
                                    <label for="">Range</label>
                                    <select
                                        name=""
                                        id=""
                                        v-model="filter.range"
                                        @change="getStats()"
                                        class="form-control form-control-sm"
                                    >
                                        <option :value="null">Select Range</option>
                                        <option value="DAY">Today</option>
                                        <option value="WEEK">Week</option>
                                        <option value="MONTH">Month</option>
                                        <option value="YEAR">Year</option>
                                        <!-- <option value="CUSTOM">Custom</option> -->
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 pad-0 pad-top-5">
                                <div class="row margin-0" v-if="stats && stats.currency">
                                    <template v-for="(itm, idx) in Object.keys(stats.currency)" :key="idx">
                                        <div class="col-4 margin-bottom-15" v-if="stats.currency[itm]">
                                            <div class="card pad-10">
                                                <span>Currency: <b>{{itm}}</b></span>
                                                <hr class="margin-0">
                                                <ul class="list-inline pad-top-10">
                                                    <li>
                                                        Total: {{stats.currency[itm].total}}
                                                    </li>
                                                    <li>
                                                        Deductables: {{stats.currency[itm].tax.deductable}}
                                                    </li>
                                                    <li>
                                                        <hr class="margin-bottom-5 margin-top-5">
                                                        Net: {{stats.currency[itm].total - stats.currency[itm].tax.deductable}}
                                                    </li>
                                                    <li>
                                                        {{stats.currency[itm].tax}}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
export default {
  data() {
    return {
        stats: null,
        filter: {
            report: null,
            range: 'MONTH',
            start: moment().format('DD-MM-YYYY'),
            end: moment().format('DD-MM-YYYY'),
        }
    };
  },
  created() {
      this.getStats()
  },
  methods: {
      getStats() {
          this.$cs.admin.create({
            data: this.filter
          })
          .then(res => {
              this.stats = res.data
          })
      },
  }
}
</script>
<style scoped>
</style>
